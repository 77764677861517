.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: -1px !important;
  border-top-color: #d2ccb6 !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-top-color: transparent !important;
  border-bottom-color: #d2ccb6 !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  top: 4px !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  right: 5px !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: #d2ccb6 !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  left: 5px !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-right-color: #d2ccb6 !important;
  border-left-color: transparent !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  border-top-color: transparent !important;
  border-bottom-color: #d2ccb6 !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  top: 4px !important;
}

.rc-tooltip-inner {
  min-width: 40px;
  text-align: center;
  width: fit-content;
  background-color: #d2ccb6;
  color: #23242a;
  padding: 8px;
  min-height: 25px;
  text-align: center;
  box-shadow: none;
}

.rc-tooltip {
  filter: drop-shadow(0px 0px 6px hsl(0deg 0% 0% / 0.1));
  max-width: 250px;
  font-size: 13px;
  opacity: 1;
}

.rc-tooltip.jp {
  font-size: 11px;
}

.rc-tooltip-placement-top {
  padding-bottom: 4px !important;
}

.empty {
  display: none;
}
